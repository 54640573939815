import { Slider } from "@mui/material";
import { styled } from "@mui/material";
import { TableCell, tableCellClasses,TableRow } from "@mui/material/";

// sliders style
export const PrettoSlider = styled(Slider)({
    height: 8,
    "& .MuiSlider-markLabel": {
      color: "white"
    },
    "& .MuiSlider-step": {
      color: "blue"
    },
    "& .MuiSlider-thumb": {
      backgroundColor: "white",
      "&:focus, &:hover, &.Mui-active, &.Mui-focusVisible": {boxShadow: "inherit",color: "white"},
      "&:before": {display: "none",},
    },
    "& .MuiSlider-valueLabel": { lineHeight: 1.2, color: "#52af77", fontSize: 12,
     background: "unset", padding: 20, width: 32, height: 32, borderRadius: "50% 50% 50% 0",
   transformOrigin: "bottom left",
     transform: "translate(50%, -100%) rotate(-45deg) scale(0)", "&:before": { display: "none" },
     "&.MuiSlider-valueLabelOpen": { transform: "translate(50%, -100%) rotate(-45deg) scale(1)",
     color: "#52af77", fontWeight:"bold" }, "& > *": { transform: "rotate(45deg)"}}});

// sliders style
export const OptimisationSlider = styled(Slider)({
  height: 8,
  "& .MuiSlider-markLabel": {
    color: "rgb(255,255,255)",
    fontWeight: "bold"
  },
  "& .MuiSlider-step": {
    color: "white"
  },
  "& .MuiSlider-track":{
    color: "white"
  },
  "& .MuiSlider-thumb": {
    backgroundColor: "rgb(250,16,105)",
    "&:focus, &:hover, &.Mui-active, &.Mui-focusVisible": {boxShadow: "inherit",color: "white"},
    "&:before": {display: "none",},
  },
  "& .MuiSlider-valueLabel": { lineHeight: 1.2, color: "#52af77", fontSize: 12,
   background: "rgb(255,255,255)", padding: 10, width: 16, height: 16, borderRadius: "50% 50% 50% 0",
 transformOrigin: "bottom left",
   transform: "translate(50%, -100%) rotate(-45deg) scale(0)", "&:before": { display: "none" },
   "&.MuiSlider-valueLabelOpen": { transform: "translate(50%, -100%) rotate(-45deg) scale(1)",
   color: "rgb(250,16,105)", fontWeight:"bold" }, "& > *": { transform: "rotate(45deg)"}}});
// VALUE LABEL 
export function valueLabelFormat(value) {
    const units = ['%']
    let unitIndex = 0
    let scaledValue = value
    return `${scaledValue} ${units[unitIndex]}`
  }

// MODAL APERCU ET SAVE
export const style = { position: "absolute", top: "50%", left: "50%", color: "aliceblue", transform: "translate(-50%, -50%)",
  width: 500, bgcolor: "#282c34", border: "2px solid #000", borderRadius: "15px", boxShadow: 24, textAlign: "center",
  display: "flex", flexDirection: "column", p: 4,
};

// MODAL + infos
export const infosStyle = { position: "absolute", top: "50%", left: "50%", color: "aliceblue", transform: "translate(-50%, -50%)",
  width: 500, bgcolor: "#282c34", border: "2px solid #fff", borderRadius: "15px", boxShadow: 24, textAlign: "center",
  display: "flex", flexDirection: "column", p: 4,alignItems:"center", paddingBottom: 10
};


// table style
export const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.common.black,
    color: "#fff",
    fontWeight: "bold"
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
    fontWeight: "bold",
    textAlign: "start"
  },
}));
export const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.action.hover,
    fontWeight: "bold",
    textAlign: "end"
  },
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));