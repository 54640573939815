import React from "react";
import { Paper,Box,Button,Typography,Modal,Table,TableContainer,TableHead,TableRow,TableBody } from "@mui/material/";
import Sliders from './Sliders'
import { style, StyledTableCell, StyledTableRow } from "./style/style";
import { useSelector } from "react-redux";

function createData(data, values,color) {
  return { data, values,color };
}

export default function AperçuModal() {

  const simulation = useSelector((state) => state.simulation.data); 

  const rows = [
    createData("Tjm Consultant ", `${simulation.tjm}€`,'#52af77' ), createData("Taux de gestion GW ", `${simulation.tgest}%`,'red'),
    createData("Nb de jours facturés", `${simulation.tps} jours`,'blue'), createData("Frais de gestion GW", `${simulation.com}€`,'red'),
    createData("Solde du consultant", `${simulation.solde}€`,'#52af77'), createData("Compte de reserve brut*", `${simulation.reserve}€`,'#52af77'),
    createData("Frais du consultant", `${simulation.frais}€`,'red'),createData("Charges patronales", `${simulation.t_cot_pat}€`,'red'),
    createData("Charges salariales", `${simulation.t_cot_sal}€`,'red'),createData("SALAIRE NET", `${simulation.netAvImpots}€`,'#52af77'),
    createData("Plan épargne entreprise", `${simulation.pee}€`,'#52af77'),createData("Plan épargne retraite", `${simulation.per}€`,'#52af77'),
    createData("Comité d'entreprise", `${simulation.ce}€`,'#52af77'),createData("Net Versé", `${simulation.netVerse}€`,'green')];
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  return (
    <div>
      {/* SAVE SIMULATION OPEN MODAL BUTTON */}
      { simulation.netAvImpots ?<Button
        className="aperçu info" sx={{ marginTop: 2,marginBottom:2, fontWeight: "bold"}}
        onClick={handleOpen}>Aperçu détaillé</Button> :""}
      <Modal
        className="table" open={open} onClose={handleClose} aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description">
        {/* MODAL CONTENT */}
        <Box sx={style}>
          <Typography id="modal-modal-title" variant="h6" sx={{ fontWeight: "bold", marginBottom: 2 }}
          component="h2">Aperçu détaillé des résultats de la simulation</Typography>
          <div className="align">
            {simulation.netAvImpots ? (<TableContainer component={Paper} sx={{maxHeight: 400}}>
            <Table stickyHeader><TableHead><TableRow>
                      <StyledTableCell sx={{  }}>Données</StyledTableCell>
                      <StyledTableCell sx={{  }}>Valeurs</StyledTableCell>
                    </TableRow></TableHead><TableBody>
                    {rows.map((row) => (
                      <StyledTableRow   key={row.data}>
                        <StyledTableCell component="th" scope="row">
                          {row.data}
                        </StyledTableCell>
                        <StyledTableCell sx={{color:row.color}} align="center">
                          {row.values}
                        </StyledTableCell></StyledTableRow> ))}
                  </TableBody></Table></TableContainer>) : ("")}
          </div>{ !simulation.netAvImpots ?  <Sliders simulation={simulation} /> : "" }
        </Box>
      </Modal>
    </div>
  );
}
