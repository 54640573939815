import *  as React from "react";
import { useState } from "react";
import { Box, Typography } from "@mui/material/";
import { useDispatch } from "react-redux";
import { getSimulation } from "../../store/actions/SimulationActions";
import { PrettoSlider,valueLabelFormat,OptimisationSlider } from "./style/style";
import  GetInfos  from './GetInfos'
export default function CustomizedSlider(props) {

  const dispatch = useDispatch();

  let simResult = props.simulation;
  
  const [formData, setFormData] = useState({});
  const [peeValue, setPeeValue] = useState(simResult.pee);
  const [ceValue, setCeValue] = useState(simResult.ce);
  const [perValue, setPerValue] = useState(simResult.per);
  const [globalValue, setGlobalValue] = useState(0)

  // + D'INFOS PAGE
  const handleChangeAll = (event, newValue) => {
    if (event.target.name === "pee") { setPeeValue(newValue) }
    else if (event.target.name === "per") { setPerValue(newValue) }
    else if (event.target.name === "ce") { setCeValue(newValue) }
    setFormData({ tjm: simResult.tjm,frais: simResult.frais,tps: simResult.tps,
      tauxImpots: simResult.tauxImpots, pee: peeValue, ce: ceValue, per: perValue })
  };

 // single slider 
  const handleChangeSingle = (event, newValue) => {
    setGlobalValue(newValue)
    setPeeValue((simResult.maxPee * globalValue) / 100)
    setPerValue((simResult.maxPer * globalValue) / 100)
    setCeValue((simResult.maxCe * globalValue) / 100)
    setFormData({tjm: simResult.tjm, frais: simResult.frais, tps: simResult.tps,
                 tauxImpots: simResult.tauxImpots,pee: peeValue,per: perValue,ce: ceValue})}

  const handleSubmit = (event) => {
    event.preventDefault();
    dispatch(getSimulation(formData))};

  const midPee = simResult.maxPee / 2, midPer = simResult.maxPer / 2, midCe  = simResult.maxCe  / 2
  const marksPee = [{ value: 0,label: '0€' },{ value: midPee,label: '50%' },{ value: simResult.maxPee, label: 'max €' }]
  const marksCe = [{ value: 0, label: '0€' },{ value:midCe, label: '50%' },{ value:simResult.maxCe, label: 'max €' }]
  const marksPer = [{ value: 0, label: '0€' },{ value:midPer,  label: '50%' },{ value:simResult.maxPer, label: 'max €'  }]
  const marksAll = [{ value: 0, label: '0%' },{ value: 50, label: '50%' }, { value: 100, label: 'max %' }]

  // @todo  gérer le changement d'état en fonction du component :
  let SliderOnMainPage = true
  return (
    <Box>
      {SliderOnMainPage === true ? <>
       <p className="title-card-dark">Optimisation GW</p>  
       <OptimisationSlider   name="opti" marks={marksAll}
        valueLabelFormat={valueLabelFormat} onChange={handleChangeSingle}
        onChangeCommitted={handleSubmit} aria-label="pretto slider"
        value={globalValue} min={0} max={100}
        valueLabelDisplay="auto" sx={{width: 200 }}/>
        <GetInfos />
       { simResult.gain !== 0 ?<p className="text-card-light bold gold">Gain supplémentaire:   {simResult.gain}€</p> : ""}

      </>  : ( <>
      {/* PEE */}
      <Typography className="net" sx={{ fontWeight: "bold", color:"#52af77",marginBottom:2 }}>
        Plan Épargne Entreprise
      </Typography>
      <PrettoSlider name="pee" onChange={handleChangeAll} onChangeCommitted={handleSubmit} valueLabelDisplay="auto"
        aria-label="pretto slider" valueLabelFormat={valueLabelFormat} value={peeValue} marks={marksPee}
        max={simResult.maxPee} min={0} sx={{ color: "#52af77", width: 200 }}/>
       {/* PER */}
       <Typography className="net" gutterBottom sx={{ fontWeight: "bold", color: "#307aa5",marginBottom:2 }}>
        Plan Épargne Retraite
      </Typography>
      <PrettoSlider name="per" onChange={handleChangeAll} onChangeCommitted={handleSubmit} valueLabelDisplay="auto"
        aria-label="pretto slider" valueLabelFormat={valueLabelFormat} value={perValue} marks={marksPer}
        max={simResult.maxPer} min={0} sx={{ width: 200 }}/>
       {/* CE */}
       <Typography className="net" gutterBottom sx={{ fontWeight: "bold", color: "#a53030",marginBottom:2 }}>
        Comité D'entreprise
      </Typography>
      <PrettoSlider name="ce" onChange={handleChangeAll} onChangeCommitted={handleSubmit} valueLabelDisplay="auto"
      aria-label="pretto slider" valueLabelFormat={valueLabelFormat} value={ceValue} marks={marksCe}
      max={simResult.maxCe} min={0} sx={{ width: 200, color: "#a53030" }}/>
   </>)}
    </Box>
  );
}
