import './layout.css'
import { NavLink } from 'react-router-dom';
import { ReactComponent as LogoGw } from '../../../src/logo-GW-white.svg'

const Nav = (props) => {
    // const { title } = props
    return (
        <div className='navigation'>
            <NavLink to={'/'}>{<LogoGw height={20}  />}</NavLink>
            
        </div>
    )
}
export default Nav;