import React, { useState } from "react";
import { ReactComponent as LogoGw } from '../../../src/logo-GW-white.svg'
import { Box,Button,Typography,Modal,TextField } from "@mui/material";
import { useSelector } from "react-redux";
import { infosStyle } from "./style/style"
import { useNavigate } from 'react-router-dom';
export default function GetInfos() {
  const navigate = useNavigate()
  const simulation = useSelector((state) => state.simulation.data)
  const [open, setOpen] = React.useState(false);
  const [formData, setFormData] = useState({email: ""})
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const handleChange = (event) =>  setFormData(event.target.value);
  const handleSubmit = async (event) => {
    event.preventDefault();
    if (formData.email === "") {
       alert('field required')
    } else {
      const randomID = Math.floor(Math.random() * 11357)
      const randomITEM = "item" + Math.floor(Math.random() * 11357)
      navigate('/simulator/' + randomID, { state: {randomID, randomITEM} })
      setFormData({ email: "" });
    }
  };

  return (
    <div> { simulation.netVerse ?
          <Button className="main-button" sx={{ marginBottom: 2, fontWeight: "bold" }} onClick={handleOpen}>+ D'INFOS</Button> : ""}
          <Modal open={open} onClose={handleClose} aria-labelledby="modal-modal-title" aria-describedby="modal-modal-description">
                 <Box component="form" onSubmit={handleSubmit} noValidate autoComplete="off" sx={{ backgroundColor:"rgb(16,23,96)", }}  style={infosStyle}>
                      <div className="margin-top"><LogoGw  height={30} /></div>
                      <Typography name="email" id="modal-modal-title" variant="h6" sx={{ fontWeight: "bold", color:"rgb(250,16,105)", marginBottom: 2 }}
                                  component="h2">Remplissez le formulaire pour accéder au <span className="white">simulateur complet </span>!</Typography>
                      <TextField required value={formData.email} onChange={handleChange}  sx={{ borderRadius:5, marginBottom: 2, backgroundColor:"white",width: 300 }} placeholder="Adresse Mail"  />
                      <Button type="submit" className="main-button"  sx={{  width: 200,fontWeight:"bold" }} >Accéder au simulateur Complet</Button>
                 </Box>
        
          </Modal>
    </div>
  );
}
