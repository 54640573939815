import { HashRouter, Route, Routes } from 'react-router-dom'
import MainLayout from './layouts/MainLayout'
import Home from './pages/HomePage'
import SimulatorIdPage from './pages/SimulatorIdPage'
import './App.css';
const random = Math.floor(Math.random() * 11357)
const users = [
  { id: 1, name: 'User 1' },
  { id: 2, name: 'User 2' }
]
const AppRoutes = () => (
  
    <MainLayout>
      <Routes>
        <Route path='/' element={ <Home /> } />
        <Route path='simulator/:id' exact element={<SimulatorIdPage/> }/>
      </Routes>
    </MainLayout>
)
export default function App() {
  return (
    <HashRouter>
      <Routes>
        <Route path='/*' element={ <AppRoutes /> } />
      </Routes>
    </HashRouter>
  );
}
