import axios from "axios";
import { GET_SIMULATION_DATA, SAVE_SIMULATION_DATA } from "./ActionTypes";

export const getSimulation = (query) => {
  return async (dispatch) => {
    try {
      if (query === undefined || query.tjm === "0" || query.tps === "0") {
        dispatch({ type: GET_SIMULATION_DATA, payload: "" })
      } else {
      await axios
        //.post(`${process.env.GENTLEMANWORKER_API_ENDPOINT}/calcPayslipFromGlobalAmount`, query)
        .post("https://api.dev.gentlemanworker.tech/calcPayslipFromGlobalAmount", query)
        .then((response) => {
          dispatch({ type: GET_SIMULATION_DATA, payload: response.data });
        })
        .catch((err) => {
          console.log(err);
        });
      }
    } catch (err) { return console.log(err) }
  };
};

export const saveSimulation = (formData, simData) => {
  return async (dispatch) => {
    try {
      console.log(simData.netAvImpots)
      if (simData.netAvImpots === undefined) {
        console.log('pas de données de simulation')
        let error = { resultat: "error" }
        dispatch({ type: SAVE_SIMULATION_DATA, payload: error })
      } else {
      let q = { formData, simData }
      await axios
       // .post(`${process.env.GENTLEMANWORKER_API_ENDPOINT}/saveCalcPayslipFromGlobalAmount`, q)
        .post("https://api.dev.gentlemanworker.tech/saveCalcPayslipFromGlobalAmount", q)
        .then((response) => {
          console.log(
            "axios response data in store [saveSimulation]",
            response.data
          );
          let success = {resultat: "success"}
          dispatch({ type: SAVE_SIMULATION_DATA, payload: success });
        })
        .catch((err) => {
          console.log(err);
        });
      }
    } catch (err) {
      return console.log(err);
    }
  };
};

