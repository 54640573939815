import React, { useState } from "react";
import { Box, TextField,Button,Typography,Modal } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { saveSimulation } from "../../store/actions/SimulationActions";
import { style } from "./style/style"

export default function BasicModal() {

  const dispatch = useDispatch();
  const simulation = useSelector((state) => state.simulation.data)
  const [formDataModal, setFormDataModal] = useState({ name: "",email: "" });
  const handleChange = (event) => {setFormDataModal({ ...formDataModal,[event.target.name]: event.target.value })};
  const handleSubmitModalForm = (event) => {
    event.preventDefault();
    dispatch(saveSimulation(formDataModal,simulation))
    setFormDataModal({ name: "", email: "" })
    handleClose()
  };
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  return (
    <div> { simulation.netVerse ?
      <Button className="save" sx={{ marginBottom: 2, fontWeight: "bold" }}
              onClick={handleOpen}>Enregistrer Ma Simulation</Button> : ""}
      <Modal open={open} onClose={handleClose} aria-labelledby="modal-modal-title"
             aria-describedby="modal-modal-description">
        <Box component="form" sx={style}>
          <Typography id="modal-modal-title" variant="h6" sx={{ fontWeight: "bold", marginBottom: 2 }}
            component="h2">Enregistrer Ma Simulation</Typography>
          <div className="col">
            <TextField name="name" type="text" onChange={handleChange} id="name" label="Jean Dupont" className="inputModal"
              variant="standard" value={formDataModal.name}
              sx={{ backgroundColor: "aliceblue", borderRadius: 1, textAlign: "center", justifyContent: "center", marginBottom: 3,  }} />
            <TextField name="email" type="email" onChange={handleChange} value={formDataModal.email} required id="email"
              label="email@email.fr"  className="inputModal"  variant="standard"
              sx={{  backgroundColor: "aliceblue", borderRadius: 1, textAlign: "center", justifyContent: "center" }}/>
            <Button sx={{ marginBottom: 2, marginTop: 2, fontWeight: "bold" }} className="greens"
              onClick={handleSubmitModalForm}>Enregistrer</Button>
          </div>
        </Box>
      </Modal>
    </div>
  );
}
