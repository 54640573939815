import Form from './Form'
import { useSelector } from 'react-redux';
import { Alert,Card,CardContent,Button} from '@mui/material';
import ConstructionIcon from '@mui/icons-material/Construction';
import SummarizeTwoToneIcon from '@mui/icons-material/SummarizeTwoTone';

const Home = (props) => { 
   
    const simulation = useSelector((state) => state.simulation.data);
    const resultat = useSelector((state) => state.save.data) 
    return (
            <div className='App-body'>
              {/* ALERTS */}
             <div className="App-header">
                    { resultat.resultat === "success" ? <Alert  sx={{marginTop: 2}} severity='success'>Mail envoyé, veuillez vérifier vos mails</Alert> : ""}
                    { resultat.resultat === "error" ? <Alert  sx={{marginTop: 2}} severity='error'>Vous devez effectuer une simulation pour enregistrer vos résultats</Alert> : ""}
                    { resultat.resultat === "" ? <Alert  sx={{marginTop: 2}} severity='info'>NODEMAILER NOT WORKING</Alert> : ""}
                    {/* TITRES */}
                    <h3 className='secondary-text-color'>Calculateur De Revenu<br/><span className='white'>Portage Salarial</span></h3>
             </div>
             <div className='row'>
             {/* CARD RESULTATS */}
             <div className="col">
                     {simulation.netAvImpots ? <div> <Card className='card-light' sx={{marginBottom: 5}}>
               <CardContent>  
                    <p className='title-card-light aligned' component="div">Résultats<SummarizeTwoToneIcon  fontSize='2'/></p> 
                    <p className='text-card-light'>Salaire Brut : <span className='secondary-text-color'>~{simulation.brut + "€"}</span></p>
                    <p className='text-card-light'>Salaire Net : <span className='secondary-text-color'>~{simulation.netAvImpots + "€"}</span></p>                                
                    <p className='text-card-light'>Taux de restitution : <span className='orange'>{simulation.tauxRest}%</span></p>
                    <p className='text-card-light bold'>Net Versé: <span className='netv'>{simulation.netVerse}€</span></p>
             </CardContent>
             </Card>
             <Card className='card-dark-variant'>
              <CardContent>
                 <p className='title-card-dark aligned'>Paramètres <ConstructionIcon fontSize='2' /></p>
                 <h3 className='bordered'>
                        <p className='text-card-dark'>Taux journalier moyen : <span className='alt-secondary-text-color'>{simulation.tjm + "€"}</span></p>
                        <p className='text-card-dark'>Frais profesionnels : <span className='secondary-text-color'>{simulation.frais + "€"}</span></p> 
                        <p className='text-card-dark'>Temps facturé : <span className='alt-secondary-text-color'>{simulation.tps + " jours"}</span></p> 
                        <p className='text-card-dark'>Taux d'imposition : <span className='secondary-text-color'>{simulation.tauxImpots}%</span></p> 
                 </h3>
               </CardContent>
             </Card>
           </div>: ""} 
          </div>
            <Form  /> 
        </div>
      </div>
         );
};
export default Home;
