import "./style/style.css";
import Modal from "./SaveSimulation";
import Sliders from "./Sliders";
import Aperçu from "./Apercu";
import { Card, CardContent, Alert, Box, TextField, Button, Checkbox, FormGroup, FormControlLabel} from "@mui/material";
import { useState } from "react";
import CalculateIcon from "@mui/icons-material/Calculate";
import EuroTwoToneIcon from "@mui/icons-material/EuroTwoTone";
import CalendarTodayTwoToneIcon from "@mui/icons-material/CalendarTodayTwoTone";
import PercentTwoToneIcon from "@mui/icons-material/PercentTwoTone";
import { useDispatch, useSelector } from "react-redux";
import { getSimulation } from "../../store/actions/SimulationActions";

const Form = () => {
  const simulation = useSelector((state) => state.simulation.data);
  const dispatch = useDispatch();
  const [formData, setFormData] = useState({  tjm: "",  frais: "",  tps: "", tauxImpots: "",  ce: "",  pee: "",  per: "" });
  const [isChecked, setIsChecked] = useState(false);
  const [isEmpty, setIsEmpty] = useState();
  
  const handleCheckBoxChange = () => {setIsChecked(!isChecked)};
  const handleChange = (event) => {
    setFormData({ ...formData, [event.target.name]: event.target.value });
    setIsEmpty(false);
  };
  const handleSubmit = (event) => {
    event.preventDefault();
    if (formData.tjm === "" || formData.tps === "") {
      setIsEmpty(true);
    } else {
      dispatch(getSimulation(formData));
      setFormData({ tjm: "", frais: "", tps: "", ce: "", pee: "",  per: "",  tauxImpots: "11" });
    }
  };
  const handleReset = () => {
    dispatch(getSimulation());
  };

  return (
    <div>
      {/* FORM */}
      {!simulation.netAvImpots ? 
      <div>
        <Box component="form" noValidate autoComplete="off" onSubmit={handleSubmit}
          sx={{ borderRadius: 3,padding:10,  border: 4,  backgroundColor: "rgb(16, 23, 96)",
            "& .MuiInputBase-root": { color: "rgb(16, 23, 96)", fontWeight: "bold", fontSize:40  },
            "& .MuiTextField-root": { color: "rgb(16, 23, 96)",  m: 2,  width: "25ch",  justifyContent: "center"}}} >    
           <div className="col justify-center">
              {/* ALERT EMPTY */}
              {isEmpty === true ? 
                <Alert sx={{ marginTop: 2 }} severity="error">Veuillez Renseigner les champs requis
                </Alert> :  ""}
                <div className="justify-center justify-better">
              {/* INPUT TJM */}
              <div className="justify-center">
                <label className="input-text" htmlFor="tjm">Taux Journalier</label>
                <TextField name="tjm" type="number" onChange={handleChange} required
                           id="tjm" className="input" variant="standard" value={formData.tjm}
                           sx={{ borderRadius: 1, justifyContent: "center" }}/>
                <EuroTwoToneIcon />
              </div>
              {/* INPUT FRAIS */}
              <div className="justify-center">
                <label className="input-text" htmlFor="frais">Frais Pros</label>
                <TextField type="number" name="frais" className="input" onChange={handleChange}
                           id="frais" variant="standard" value={formData.frais} sx={{ borderRadius: 1,justifyContent: "center" }}/>
                <EuroTwoToneIcon />
              </div>
              {/* INPUT TEMPS */}
              <div className=" justify-center">
                <label className="input-text" htmlFor="tps">Période (jours)</label>
                <TextField className="input" type="number" name="tps" onChange={handleChange}
                           required id="tps" variant="standard" value={formData.tps} sx={{ borderRadius: 1, justifyContent:"center" }}/>
                <CalendarTodayTwoToneIcon />
              </div>
              </div>
              {/* CHECKBOX ET INPUT TAUX D'IMPOTS */}
              <div className="justify-center">
                <FormGroup>
                      <FormControlLabel className="bold" control={
                      <Checkbox sx={{color: "rgb(250,16,105)","&.Mui-checked": { color: "rgb(250,16,105)" },fontWeight: "900", }}
                                checked={isChecked} onChange={handleCheckBoxChange}/>} label="Je connais mon taux d'imposition"/>
                </FormGroup>
                {isChecked ? <div className="justify-center"> <TextField className="input"  type="number"  name="tauxImpots"  onChange={handleChange}
                                 required id="tauxImpots" variant="standard" value={formData.tauxImpots}
                                 sx={{ backgroundColor: "aliceblue", borderRadius: 1 }} />
                                   <PercentTwoToneIcon />
                             </div> :  ""}
              </div>
                <Button className="main-button"  sx={{ marginBottom: 2, fontWeight: "bold" }} type="submit"
                    variant="contained">Calculer Mes Revenu <CalculateIcon />
                </Button> 
            </div>
        </Box>
        </div>  : "" }
      {/* BUTTONS */}
      {simulation.netAvImpots ? 
        <div> <Card className="card-dark" sx={{ marginBottom: 5 }}>
                  <CardContent> <Sliders simulation={simulation} /> </CardContent>
              </Card>
              <Card className="card-light-variant" sx={{}}>
                  <CardContent><p className="title-card-light">Menu</p>
                  <Modal className="width-100" /><Aperçu className="width-100"/>
                  <Button className="width-100 main-button bold" onClick={handleReset} variant="filled">Nouvelle Simulation
                  </Button>
            </CardContent>
          </Card>
        </div> : "" }
    </div>
  );
};
export default Form;
